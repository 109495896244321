import React, { useEffect } from "react";
import p from "../../Assets/Group 1.jpg";
import { FaInstagram, FaSquareFacebook, FaXTwitter } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io";
import { FaLinkedin, FaWhatsapp } from "react-icons/fa";
import { LuPhoneCall } from "react-icons/lu";
import "../Footerpage/Footer.css";
import { Link } from "react-router-dom";
const Footer = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <>

      <div className="footer">

        <div className="footer-sec">
          <div className="first">
            <img src={p} alt="" />
            <p id="foot-p">
              We are a Conversational Engagement <br /> Platform empowering
              businesses to <br /> engage meaningfully with customers <br />{" "}
              across commerce, marketing and <br />
              support use-cases on 30+ channels
            </p>
            <div className="social-links">
              <a target="_blank" href="https://www.instagram.com/hypersage_tech/">
                <FaInstagram />
              </a>
              <a target="_blank" href="https://www.facebook.com/profile.php?id=61553607470334">
                <FaSquareFacebook />
              </a>
              <a href="">
                <IoLogoYoutube />
              </a>
              <a target="_blank" href="https://www.linkedin.com/company/hypersage/mycompany/">
                <FaLinkedin />
              </a>
              <a href="">
                <FaXTwitter />
              </a>
              <a target="_blank" href="https://wa.me/9108433133">
                <FaWhatsapp />
              </a>
            </div>
          </div>
          <div>
            <div className="About">
              <p className="head">Quick Links</p>
              <Link to="/about">

                <p>About</p>
              </Link>
              <Link to="/carrer">
                <p>Careers</p>
              </Link>
              <Link to="/gallery">
                <p>Gallery</p>
              </Link>
              <Link to="/newsroom">
                <p>Newsroom</p>
              </Link>
            </div>
          </div>
          <div>
            <div className="services">
              <p className="head">Services</p>
              <Link to="/software-solution">

                <p>Software Solution</p>

              </Link>
              <Link to="/brand-promotion">

                <p>Brand Promotion</p>

              </Link>
              <Link to="/sms-service">

                <p>SMS Services</p>

              </Link>
              <Link><p>API Integration</p></Link>


            </div>
          </div>
          <div className="news">
            <p className="head">News Letter</p>
            <div className="phn">
              <p>
                <LuPhoneCall className="call" />
              </p>{" "}
              <div className="sp">
                <span>Need help? 24/7 </span> <br /> <span>+91 6370 138 771 </span>
              </div>
            </div>
            <p className="news-sec">
              Hypersage Technology PVT LTD  <br /> 2nd Floor of 

              No. 263 ,21st Main,
              <br />3rd Cross {" "}
              <br />
              ,BTM 2nd Stage,Bengaluru, Karnataka

              560076.
            </p>
            <p>
              <input type="email" placeholder="enter your email here" name="" id="" />
              <input type="button" value="Enter" />
            </p>
          </div>
        </div>
        <hr />
        <div className="footer-section">
          <p> &copy;2024 All Rights Reserved | Design and Devlopment By<a  target="_blank"> Hypersage </a></p>
          <p>
            <Link to="termspage">
              <a href="#team">Team Of Services</a> |{" "}
            </Link>
            <Link to="privaypage">
              <a href="#privacy-policy">Privacy Policy</a> |{" "}
            </Link>
            <Link to="privaypage">
              <a href="#cookies-policy">Cookies Policy</a>
            </Link>

          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
